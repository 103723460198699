import type { NavigationGuardNext, Position, PositionResult, Route, RouteConfig } from 'vue-router/types/router';
import type { VuetifyGoToTarget } from 'vuetify/types/services/goto';
import goTo from 'vuetify/lib/services/goto';
import VueRouter from 'vue-router';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { getCurrentStaffData, removeStaffCookies } from '@/assets/auth_helper';
import store from '@/store';
import ENV_SETTING from 'config';

import Error from '@/pages/Error.vue';
Vue.use(VueRouter);

const vm = new Vue();

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: ['common.login'],
      skipAuth: true,
      requireAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      layout: 'content',
      title: ['dashboard.dashboard'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/client/detail/:id',
    name: 'ClientDetail',
    component: () => import('@/pages/ClientDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.detail-text', { name: 'client.client' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/client/add',
    name: 'AddClient',
    component: () => import('@/pages/ClientDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.add-new', { name: 'client.client' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/client/quotation-list/:id',
    name: 'ClientQuotationList',
    component: () => import('@/pages/ClientQuotationList.vue'),
    meta: {
      layout: 'content',
      title: ['client.customer-quotation'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/client/quotation-list',
    redirect: '/client',
  },
  {
    path: '/client/invoice-list/:id',
    name: 'ClientInvoiceList',
    component: () => import('@/pages/ClientInvoiceList.vue'),
    meta: {
      layout: 'content',
      title: ['client.customer-invoice'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/client/invoice-list',
    redirect: '/client',
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import('@/pages/Client.vue'),
    meta: {
      layout: 'content',
      title: ['client.client-management'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/staff/detail/:id',
    name: 'StaffDetail',
    component: () => import('@/pages/StaffDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.detail-text', { name: 'staff.staff' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/staff/add',
    name: 'AddStaff',
    component: () => import('@/pages/StaffDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.add-new', { name: 'staff.staff' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/staff',
    name: 'Staff',
    component: () => import('@/pages/Staff.vue'),
    meta: {
      layout: 'content',
      title: ['staff.staff-management'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/quotation/detail/:id',
    name: 'QuotationDetail',
    component: () => import('@/pages/QuotationDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.detail-text', { name: 'quotation.quotation' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/quotation/add',
    name: 'AddQuotation',
    component: () => import('@/pages/QuotationDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.add-new', { name: 'quotation.quotation' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/quotation',
    name: 'Quotation',
    component: () => import('@/pages/Quotation.vue'),
    meta: {
      layout: 'content',
      title: ['quotation.quotation'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/quotation-service',
    name: 'QuotationService',
    component: () => import('@/pages/QuotationService.vue'),
    meta: {
      layout: 'content',
      title: ['quotation.service-item'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/quotation-setting',
    name: 'QuotationSetting',
    component: () => import('@/pages/QuotationSetting.vue'),
    meta: {
      layout: 'content',
      title: ['common.setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/invoice/detail/:id',
    name: 'InvoiceDetail',
    component: () => import('@/pages/InvoiceDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.detail-text', { name: 'invoice.invoice' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/invoice/add',
    name: 'AddInvoice',
    component: () => import('@/pages/InvoiceDetail.vue'),
    meta: {
      layout: 'content',
      title: ['common.add-new', { name: 'invoice.invoice' }],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: () => import('@/pages/Invoice.vue'),
    meta: {
      layout: 'content',
      title: ['invoice.invoice'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/invoice-setting',
    name: 'InvoiceSetting',
    component: () => import('@/pages/InvoiceSetting.vue'),
    meta: {
      layout: 'content',
      title: ['common.setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },

  {
    path: '/role-permission',
    name: 'AccountRolePermission',
    component: () => import('@/pages/AccountRolePermission.vue'),
    meta: {
      layout: 'content',
      title: ['staff.account-role-permission'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/contract-management',
    name: 'ContractManagement',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['contract.contract-management'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/contract-setting',
    name: 'ContractSetting',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['contract.contract-setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/econ-plan',
    name: 'EconPlan',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['econ.econ-plan'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/econ-setting',
    name: 'EconSetting',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['econ.econ-setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/booking',
    name: 'Booking',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['booking.booking-management'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/booking-setting',
    name: 'BookingSetting',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['booking.booking-setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/log-list',
    name: 'LogList',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['log.log-list'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/log-setting',
    name: 'LogSetting',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      layout: 'content',
      title: ['log.setting'],
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: { title: ['instruction.page-not-found'] },
  },
];

const router: VueRouter = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history', // abstract, hash, history
  scrollBehavior: async (to: Route, _from: Route, savedPosition: void | Position): Promise<PositionResult> => {
    // https://vuetifyjs.com/features/scrolling/#router3067306e4f7f7528
    let scrollTo: VuetifyGoToTarget = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return { x: 0, y: await goTo(scrollTo) };
  },
  routes,
});

router.beforeEach((_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  if (_to?.meta?.requireAuth) {
    const staffData = getCurrentStaffData();

    if (
      vm.$validator.DataValid(staffData) &&
      vm.$validator.DataValid(staffData.verify_token) &&
      vm.$validator.DataValid(staffData.id)
    ) {
      next();
    } else {
      removeStaffCookies();
      if (_to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    }
  } else if (_to?.meta?.skipAuth) {
    const staffData = getCurrentStaffData();

    if (vm.$validator.DataValid(staffData)) {
      if (_to.path !== '/dashboard') {
        next('/dashboard');
      } else {
        next();
      }
    } else {
      next();
    }
  }

  next();
});

const DEFAULT_TITLE = ENV_SETTING.ENV_TITLE !== undefined &&
  ENV_SETTING.ENV_TITLE !== null &&
  ENV_SETTING.ENV_TITLE !== ''
  ? ENV_SETTING.ENV_TITLE : 'Quotation System CMS';

router.afterEach((to, from) => {
  // Hide Loading
  Vue.nextTick(() => {
    store.dispatch('DataModule/resetRemovedImage', []);

    let title = '';
    if (to['meta'] !== undefined) {
      // (to['meta'] !== undefined ? to['meta'].title : '')
      const titleArr = to['meta'].title;
      if (titleArr.length > 1) {
        const keys = Object.keys(titleArr[1]);
        const obj: any = {};
        obj[keys[0]] = i18n.t(titleArr[1][keys[0]]);
        title = i18n.t(titleArr[0], obj);
      } else {
        title = i18n.t(titleArr[0]);
      }
    }

    document.title = `${title} - ${DEFAULT_TITLE}`;
  });
});

export default router;
