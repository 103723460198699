
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import NavListItem from '@/components/NavDrawer/NavListItem.vue';
import defaultIcon from '@/assets/icon/amazing_jackie_logo.svg';
import cmsIcon from '@/assets/icon/3.png';
import ENV_SETTING from 'config';

@Component({ components: { NavListItem } })
export default class NavDrawer extends Vue {
  @Prop({ required: true, type: Array, default: () => [] }) drawerItemList: any;
  @Prop({ required: false, type: Array, default: () => [] })
  userNavActionPanel: any;
  @Prop({ required: true, type: Boolean, default: null }) isDrawerOpen: any;
  @Prop({ required: true, type: String, default: '' }) icon: any;
  @Prop({ required: false, type: String, default: 'Staff' })
  username: any;

  // data
  ajIcon: string = cmsIcon;
  routeLink: string = '';
  groupActiveState: any = {};
  logo: string = '';

  showMiniLink: boolean = false;

  get miniDrawer(): boolean {
    return this.$store.getters['ConfigModule/miniDrawer'];
  }

  get showAvatar(): boolean {
    return false;
  }

  // methods
  /** toggle nav drawer mini variant */
  toggleMiniDrawer(state: boolean): void {
    if (this.miniDrawer === state) {
      return;
    }

    this.$store.dispatch('ConfigModule/setDrawer', state);
  }

  changeLang(type: string) {
    const currentLang = this.$root.$i18n.locale;

    if (type !== currentLang) {
      this.$root.$i18n.locale = type;

      this.$store.dispatch('ConfigModule/setLocale', type);
      document.documentElement.setAttribute('lang', type);
      this.$router.go(0);
    }
  }

  navigateToHome() {
    if (this.$route.path !== this.routeLink) {
      this.$router.replace(this.routeLink);
    }
  }

  activeDrawerGroup(routerName: string): void {
    this.groupActiveState = {};

    for (let i = 0; i < this.drawerItemList.length; i++) {
      const item = this.drawerItemList[i];
      if (item.isGroup) {
        const itemName = item.items.map((obj: any) => {
          return obj.linkName;
        });

        this.groupActiveState[i] = itemName.includes(routerName);
      }
    }
  }

  getOptionIsVisible(index: number) {
    // test the case if a item is a group but all of it sub items are invisible i.e. show = false
    // true = visible
    // false = invisible
    let result = false;

    const item = this.drawerItemList[index];
    if (this.$validator.DataValid(item)) {
      const filterVisibleSubItem = item.items.filter((obj: any) => {
        return obj.show;
      });

      result = filterVisibleSubItem.length > 0;
    }

    return result;
  }

  logout() {
    this.logoutAccount();
  }

  // Watch
  @Watch('$vuetify.breakpoint.mdAndDown')
  forceLargeDrawer(isMobile: boolean): void {
    if (isMobile && this.miniDrawer) {
      this.toggleMiniDrawer(false);
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  watchRouter(router: any) {
    if (/* router.name === 'StaffStoreList' || router.name === 'MyStore' */ true) {
      // prevent the wrong position of tooltip
      this.showMiniLink = false;

      setTimeout(() => {
        this.showMiniLink = true;
      }, 300);
    }

    this.activeDrawerGroup(router.name);
  }

  @Watch('drawerItemList', { deep: true })
  getGroupActiveState(drawerItemList: Array<Object>): void {
    this.routeLink = '/';
  }

  // Emit
  @Emit('update:isDrawerOpen')
  updateDrawerStatus(val: Boolean) {
    return val;
  }

  created() {
    this.logo = ENV_SETTING.ENV_APP_IMG;
  }
}
