
import { Vue, Component } from 'vue-property-decorator';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import defaultIcon from '@/assets/icon/amazing_jackie_logo.svg';
import cmsIcon from '@/assets/icon/3.png';
import NavDrawer from '@/components/NavDrawer/NavDrawer.vue';
import NavListItem from '@/components/NavDrawer/NavListItem.vue';

@Component({ components: { NavDrawer, NavListItem } })
export default class Content extends Vue {
  // data
  userIcon: string = cmsIcon;
  ajIcon: string = cmsIcon;
  userName: string = 'Staff';
  isUserMenuOpen: boolean = false;
  miniDrawer: boolean = false;
  isDrawerOpen: boolean = false;
  disableCloseMenu: boolean = false;
  drawerItem: Array<any> = [];
  userNavActionPanel: Array<any> = [];
  icon = {
    mdiChevronDown,
    mdiChevronUp,
  };
  openLangMenu: boolean = false;

  get btnLength(): any {
    return document.getElementById('hover-btn')?.offsetWidth;
  }

  get mainContentClass() {
    return {
      'app-content-container boxed-container py-6 px-3 px-sm-6 px-md-6 px-lg-6 px-xl-6': true,
      'mb-16': this.$vuetify.breakpoint.width >= 500,
    };
  }

  // methods
  changeLang(type: string) {
    const currentLang = this.$root.$i18n.locale;

    if (type !== currentLang) {
      this.$root.$i18n.locale = type;

      document.documentElement.setAttribute('lang', type);
      this.$store.dispatch('ConfigModule/setLocale', type);
      this.$router.go(0);
    }
  }

  getUserDrawer(): Array<any> {
    return [
      {
        isGroup: false,
        show: true,
        text: 'dashboard.dashboard',
        linkName: 'Dashboard',
        icon: 'fi fi-rr-tachometer-fast',
      },
      {
        isGroup: false,
        show: true,
        text: 'client.client-management',
        linkName: 'Client',
        icon: 'fi fi-rr-tachometer-fast',
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'quotation.quotation-management',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'quotation.quotation',
            linkName: 'Quotation',
          },
          {
            show: true,
            text: 'quotation.service-item',
            linkName: 'QuotationService',
          },
          {
            show: true,
            text: 'common.setting',
            linkName: 'QuotationSetting',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'invoice.invoice-management',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'invoice.invoice',
            linkName: 'Invoice',
          },
          {
            show: true,
            text: 'common.setting',
            linkName: 'InvoiceSetting',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'staff.staff-management',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'staff.staff-management',
            linkName: 'Staff',
          },
          {
            show: true,
            text: 'staff.account-role-permission',
            linkName: 'AccountRolePermission',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'contract.contract-management',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'contract.contract-management',
            linkName: 'ContractManagement',
          },
          {
            show: true,
            text: 'contract.contract-setting',
            linkName: 'ContractSetting',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'econ.econ-plan',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'econ.econ-plan',
            linkName: 'EconPlan',
          },
          {
            show: true,
            text: 'econ.econ-setting',
            linkName: 'EconSetting',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'layout',
        text: 'booking.booking-management',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'booking.booking-management',
            linkName: 'Booking',
          },
          {
            show: true,
            text: 'booking.booking-setting',
            linkName: 'BookingSetting',
          },
        ],
      },
      {
        isGroup: true,
        show: true,
        id: 'log',
        text: 'log.log',
        icon: 'fi fi-br-layers',
        items: [
          {
            show: true,
            text: 'log.log-list',
            linkName: 'LogList',
          },
          {
            show: true,
            text: 'log.setting',
            linkName: 'LogSetting',
          },
        ],
      },
    ];
  }

  // lifecycle
  created() {
    this.$i18n.locale = this.$store.getters['ConfigModule/locale'];
    this.userName = this.getStaffUsername();
    this.userIcon = this.ajIcon;
    this.drawerItem = this.getUserDrawer();
  }
}
